exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-agreement-mobile-tsx": () => import("./../../../src/pages/account-agreement-mobile.tsx" /* webpackChunkName: "component---src-pages-account-agreement-mobile-tsx" */),
  "component---src-pages-account-agreement-tsx": () => import("./../../../src/pages/account-agreement.tsx" /* webpackChunkName: "component---src-pages-account-agreement-tsx" */),
  "component---src-pages-advisors-tsx": () => import("./../../../src/pages/advisors.tsx" /* webpackChunkName: "component---src-pages-advisors-tsx" */),
  "component---src-pages-atplayform-tsx": () => import("./../../../src/pages/atplayform.tsx" /* webpackChunkName: "component---src-pages-atplayform-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cause-funds-tsx": () => import("./../../../src/pages/cause-funds.tsx" /* webpackChunkName: "component---src-pages-cause-funds-tsx" */),
  "component---src-pages-charities-tsx": () => import("./../../../src/pages/charities.tsx" /* webpackChunkName: "component---src-pages-charities-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fees-investment-tsx": () => import("./../../../src/pages/fees-investment.tsx" /* webpackChunkName: "component---src-pages-fees-investment-tsx" */),
  "component---src-pages-fees-tsx": () => import("./../../../src/pages/fees.tsx" /* webpackChunkName: "component---src-pages-fees-tsx" */),
  "component---src-pages-foundation-tsx": () => import("./../../../src/pages/foundation.tsx" /* webpackChunkName: "component---src-pages-foundation-tsx" */),
  "component---src-pages-how-it-works-form-tsx": () => import("./../../../src/pages/how-it-works-form.tsx" /* webpackChunkName: "component---src-pages-how-it-works-form-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-mobile-tsx": () => import("./../../../src/pages/privacy-mobile.tsx" /* webpackChunkName: "component---src-pages-privacy-mobile-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-mobile-tsx": () => import("./../../../src/pages/terms-mobile.tsx" /* webpackChunkName: "component---src-pages-terms-mobile-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

